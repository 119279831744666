import createAxios from './httpCommon';
import Error from './error';



 



export default () => {
  const { error } = Error();




const getTrendingProducts = (
    setLoading,
    showToast,
    setTrendingProducts,
  ) => {
  setLoading(true)
    createAxios().then((axios) => {
      axios.get('/product')
        .then((response) => {
        console.log(response,'trending')
        
            setTrendingProducts( response?.data?.data?.trendingProducts);
           setTimeout(() => {
            setLoading(false)
           }, 1000);
          
        }).catch((e) => {
         
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 3 : true;
            error(e, setLoading,showToast, retriesDone); 
        });
    });
  };

  return {
   
   getTrendingProducts
        };
    };