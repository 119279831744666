import React, { useState, useEffect } from 'react'
import { Navbar, Nav, Button } from 'react-bootstrap'
import companyLogo from '../../assets/vs-logo.svg'

import { useLocation, useNavigate } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa';
import { useAppContext } from '../../AppContext';



const GuestNavbar = () => {
  const {isMobile} = useAppContext();
  const navigate= useNavigate()
  const location = useLocation()
  const [expanded, setExpanded] = useState(false);
  const [sectionToScroll, setSectionToScroll] = useState(null);
  const toggleNavbar = () => setExpanded(!expanded);
  const closeNavbar = () => setExpanded(false);
 
  const scrollToSection = (sectionId) => {
    if (sectionId === 'footer') {
      const footer = document.getElementById('footer');
      if (footer) {
        footer.scrollIntoView({ behavior: 'smooth' });
      }
      closeNavbar();
    }else if (location.pathname === '/') {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      closeNavbar();
    } else {
      setSectionToScroll(sectionId);
      navigate('/');
      closeNavbar();
    }
  };
  useEffect(() => {
    if (location.pathname === '/' && sectionToScroll) {
      const section = document.getElementById(sectionToScroll);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      setSectionToScroll(null);
    }
  }, [location.pathname, sectionToScroll]);

  return (
    <Navbar sticky='top' className='d-block p-0 m-0 bg-white' expand='lg' expanded={expanded} >
       <Navbar style={{backgroundColor:'#469B12', border:'1px solid #D8D8D8'}} className='py-3 d-flex gap-3 justify-content-center text-white'>
        <h4 style={{fontWeight:"400"}}>Save Up to 25% on your orders.</h4>
        <a href='https://app.virtuesshop.co' target="_blank"  
    rel="noopener noreferrer" className='text-white text-decoration-underline'><h4 style={{fontWeight:"500"}}>TRY NOW</h4></a>

     
      
    </Navbar>
   
    <Navbar className='d-flex mt-3 mt-lg-4 px-3 px-xl-4 bg-white actual-navbar position-relative' style={{
  borderBottom: '1px solid #E3F0DBB2',
  borderRadius: '40px',
  boxShadow: '1px 4px 8px 1px #469B1226'
}} expand='lg' expanded={expanded}>
  <Navbar.Brand href='/' onClick={closeNavbar}>
    <img src={companyLogo} alt='logo' className='logo' style={{ width: isMobile ? '65px' : '75px', objectFit: 'cover' }} />
  </Navbar.Brand>

  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mx-lg-auto gap-xl-2 gap-1" id="navbar-example2">
      <Nav.Link className='nav-link' onClick={() => scrollToSection('section1')}>Why Virtue's Shop</Nav.Link>
      <Nav.Link className='nav-link' onClick={() => scrollToSection('section2')}>Our Products</Nav.Link>
      <Nav.Link className='nav-link' onClick={() => scrollToSection('section3')}>Services We Offer</Nav.Link>
      <Nav.Link className='nav-link' onClick={() => scrollToSection('footer')}>Reach Out To Us</Nav.Link>
    </Nav>
    <Nav className="">
    <div className='d-flex gap-2 gap-lg-1 gap-xl-2' style={{ marginTop: expanded ? '10px' : '0px' }}>
      <Button href="https://app.virtuesshop.co/" target="_blank" rel="noopener noreferrer" className='d-none d-lg-block' style={{ borderRadius: '80px' }}>
        Get Started
      </Button>
      <Button variant='outline-primary' href="https://app.virtuesshop.co/login" target="_blank" rel="noopener noreferrer" style={{ borderRadius: '80px' }} className=''>
        Login
      </Button>
    </div>
  </Nav>
  </Navbar.Collapse>

  <Nav className="d-flex d-lg-none mx-0 px-0">
  <div className='d-flex align-items-center gap-2' style={{ marginTop: expanded ? '12px' : '0px' }}>
      <Button variant='primary' href="https://app.virtuesshop.co/" target="_blank" rel="noopener noreferrer" style={{ borderRadius: '80px', width: 'auto' }}>
        Get Started
      </Button>

      {/* Wrap the Get Started button and toggle icon in a flex container */}
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar} style={{
        marginLeft: '8px',
        position: expanded ? 'absolute' : 'relative', 
        top: expanded ? '30px' : '0',
        right: expanded ? '25px' : '0',
      }}>
        {expanded ? <FaTimes /> : <FaBars />}
      </Navbar.Toggle>
    </div>
  </Nav>
</Navbar>

    </Navbar>
  )
}

export default GuestNavbar
